const DEFAULT_PAGE_SIZE = 50
const DEFAULT_PAGE = 1

const ITEMS = [
  {
    icon: 'truck',
    title: '1. Araç seçimi yapın',
    text: '3 adımlı kolay sigorta poliçeniz için yapmanız gereken ilk şey, size uygun araç türünü seçmek.',
  },
  {
    icon: 'edit',
    title: '2. Formu doldurun',
    text: '5 adımlı kolay formunuzu güncel bilgileriniz ile doldurun. Merak etmeyin, tüm bilgileriniz bizimle güvende!',
  },
  {
    icon: 'phone-call',
    title: '3. Teklifinizi alın',
    text: 'Ak Sigorta ile sigorta teklinizi almak için 24 saat içinde yetkililerimiz sizinle iletişime geçecektir.',
  },
]

export { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, ITEMS }
