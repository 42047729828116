import { useRef } from 'react'
import Icon from './Icon'

const CallButton = ({
  position,
  text,
  icon,
  color,
  currentNumber,
  onClick,
  className = '',
}) => {
  const callRef = useRef()
  const handleClick = () => {
    onClick(() => callRef.current.click())
  }

  return (
    <div
      className={`fixed ${
        position === 'left' ? 'left-8' : 'right-8'
      } bottom-8 ${className}`}
    >
      <a
        className={`flex justify-center items-center gap-1 gradient-bg px-4 text-white text-center rounded-full h-10 shadow-sm hover:shadow-md transition-300 ease-in-out font-bold`}
        ref={callRef}
        href={currentNumber}
        onClick={handleClick}
        style={{ backgroundColor: color }}
      >
        <Icon name={icon} />
        <span>{text}</span>
      </a>
    </div>
  )
}

export default CallButton
