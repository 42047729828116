import { map, uniqueId } from 'lodash'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as XLSX from 'xlsx'

import { get, post } from '../api'
import { Button, Container, Section, Pagination } from '../components'
import { AdminNavbar } from '../sections'
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../contants'
import useSWR from 'swr'

const fetchAllApplications = async () => {
  const res = await get('/export')
  if (res && res.success) {
    return res.data
  }
  return []
}

const saveAsExcelFile = (buffer, fileName) => {
  const data = new Blob([buffer], { type: 'application/octet-stream' })
  const url = URL.createObjectURL(data)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const SectionContainer = ({ label, children, className }) => {
  return (
    <div className="flex flex-col gap-4 flex-1 overflow-x-auto p-2 sm:p-2">
      {label && <h2 className="font-bold text-lg">{label}</h2>}
      {children}
    </div>
  )
}

const ActionsContainer = ({ children }) => (
  <div className="absolute right-2 sm:right-0 flex gap-4">{children}</div>
)

const TH = ({ content }) => {
  return (
    <th
      scope="col"
      className="text-sm font-bold text-gray-900 px-6 py-4 text-left"
    >
      {content}
    </th>
  )
}

const TD = ({ content, children, bold = false }) => {
  return (
    <td
      className={`text-sm text-gray-900 px-6 py-4 whitespace-nowrap ${
        bold ? 'font-bold' : 'font-light'
      }`}
    >
      {children || content}
    </td>
  )
}

const fetcher = async url => {
  const res = await get(url)
  return res
}

const Applications = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const currentPage = parseInt(searchParams.get('page'), 10) || DEFAULT_PAGE
  const currentLimit =
    parseInt(searchParams.get('limit'), 10) || DEFAULT_PAGE_SIZE

  const { data } = useSWR(`/applications?page=${currentPage}`, fetcher)
  const { data: currentApplications, totalElements, totalPages } = data || {}

  const handlePageChange = page => {
    setSearchParams({ page })
  }

  const removeItem = async itemId => {
    if (window.confirm('Bu başvuruyu silmek istediğinize emin misiniz?')) {
      const res = await post('/remove/application', { itemId })
      if (res && res.success) {
        alert('Silme işlemi başarılı. Yeniden yönlendiriliyorsunuz...')
        setTimeout(() => {
          navigate(0)
        }, 1500)
      } else {
        alert('Silinme sırasında bir hata oluştu.')
      }
    }
  }

  const getIdx = idx => {
    return (currentPage - 1) * currentLimit + idx + 1
  }

  const handleExport = async () => {
    const header = [
      'Ad Soyad',
      'TC',
      'Telefon',
      'Plaka',
      'Doğum Tarihi',
      'Araç Türü',
      'Başvuru Tarihi',
    ]
    const data = await fetchAllApplications()
    const fileName = `Sigortam_${uniqueId()}.xlsx`
    const ws = XLSX.utils.json_to_sheet(data)
    XLSX.utils.sheet_add_aoa(ws, [header], { origin: 'A1' })
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    saveAsExcelFile(excelBuffer, fileName)
  }

  const handleDeleteAll = async () => {
    if (window.confirm('Tüm başvuruları silmek istediğinize emin misiniz?')) {
      const res = await post('/remove/applications/all')
      if (res && res.success) {
        alert('Silme işlemi başarılı. Yeniden yönlendiriliyorsunuz...')
        setTimeout(() => {
          navigate(0)
        }, 1500)
      } else {
        alert('Silinme sırasında bir hata oluştu.')
      }
    }
  }

  return (
    <Container fluid>
      <AdminNavbar />
      <Container className="flex flex-row gap-4 flex-1 mt-12 relative sm:overflow-x-auto sm:w-full">
        <ActionsContainer>
          <Button content="Dışa Aktar" onClick={handleExport} type="button" />
          <Button
            content="Hepsini Sil"
            onClick={handleDeleteAll}
            type="button"
            className="bg-red-500"
          />
        </ActionsContainer>
        <SectionContainer label="Başvurular">
          <Section
            name="cars"
            className="border shadow-sm rounded-md flex-1 overflow-y-auto sm:max-w-full md:max-h-itemCard"
          >
            <div className="flex flex-col">
              <div className="overflow-x-auto">
                <div className="py-2 inline-block min-w-full px-2">
                  <div className="overflow-hidden">
                    <table className="min-w-full">
                      <thead className="border-b">
                        <tr>
                          <TH content="#" />
                          <TH content="Ad Soyad" />
                          <TH content="TC" />
                          <TH content="Telefon" />
                          <TH content="Plaka" />
                          <TH content="Doğum Tarihi" />
                          <TH content="Aksiyonlar" />
                        </tr>
                      </thead>
                      <tbody>
                        {map(currentApplications, (application, idx) => (
                          <tr
                            key={application._id}
                            className="border-b last-of-type:border-none"
                          >
                            <TD content={getIdx(idx)} bold />
                            <TD content={application.fullName} />
                            <TD content={application.tc} />
                            <TD content={application.phone} />
                            <TD content={application.plateNumber} />
                            <TD content={application.birthday} />
                            <TD>
                              <Button
                                content="X"
                                onClick={() => removeItem(application._id)}
                                className="bg-red-600 rounded-full"
                              />
                            </TD>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Section>
          <Section>
            <Pagination
              currentPage={currentPage}
              pageSize={currentLimit}
              totalElements={totalElements}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </Section>
        </SectionContainer>
      </Container>
    </Container>
  )
}

export default Applications
