const Privacy = () => {
  return (
    <div className="w-container flex flex-col justify-start p-8 mx-auto gap-8">
      <h1 className="text-5xl font-bold">Gizlilik Şartları</h1>
      <div className="bg-slate-50 p-4 leading-5">
        <p className="leading-6">
          Gizliliğin kullanıcılarımız için çok önemli olduğunun bilincindeyiz,bu
          nedenle site kullanıcılarımızın gizliliği bizim için de çok
          önemlidir.Gizlilik Politikası, acenta360 web sitesini veya mobil
          sitesini kullanırken paylaştığınız verilerin ne amaçlarla
          kullanılabileceği,bu bilgilerin kimlerle paylaşılabileceği ve
          gizliliğinizi korumak amacıyla uyguladığımız güvenlik işlemleri
          hakkında gerekli bilgileri sağlamayı amaçlamaktadır.
        </p>
        <p className="leading-6">
          acenta360 internet sitesini, mobil uygulamalarını ve diğer iletişim
          kanallarını kullanmakla, Gizlilik Politikasında belirtilen hususları
          kabul etmiş ve burada belirtilen hususlarda acenta360'a izin vermiş
          oluyorsunuz.
        </p>
        <p className="leading-6">
          Gizlilik Politikası ile ilgili her türlü uyuşmazlık Türkiye
          Cumhuriyeti yasalarına tabidir ve uyuşmazlıkların çözümünde İstanbul
          mahkemeleri ve icra daireleri yetkilidir.
        </p>
        <p className="leading-6">acenta360 Hangi Verilerinizi Toplayabilir?</p>
        <ul className="list-disc p-4">
          <li className="leading-6">
            Adınızı, soyadınızı, adresinizi,doğum tarihinizi,ikamet ettiğiniz
            şehrinizi,telefon numaralarınızı,e-posta adresinizi ve diğer tüm
            iletişim bilgilerinizi,
          </li>
          <li className="leading-6">
            Türkiye Cumhuriyeti uyruklu kullanıcılarımız için T.C Kimlik
            numaranızı
          </li>
          <li className="leading-6">Yabancı uyruklu kullanıcılarımız için Pasaport bilgilerinizi,</li>
          <li className="leading-6">
            Sitemizden satın aldığınız ürün veya hizmete ait sipariş
            bilgilerinizi,
          </li>
          <li className="leading-6">
            Ödeme araçları ve faturalamaya ilişkin bilgilerinizi toplayabilir.
          </li>
        </ul>
        <p className="leading-5">acenta360 Verilerinizi Hangi Amaçlarla Kullanabilir?</p>
        <ul className="list-disc p-4">
          <li className="leading-6">
            Araç kiralama hizmetlerinizi gerçekleştirmek ve sitemize üyelik
            hesabınızı yönetmek,
          </li>
          <li className="leading-6">
            Çağrı merkezimizi aradığınızda size en uygun ve hızlı hizmetleri
            verebilmek,
          </li>
          <li className="leading-6">
            Kişisel seçimlerinizi analiz ederek,ürün ve hizmetlerimizi bu
            doğrultuda iyileştirmek ve geliştirmek,
          </li>
          <li className="leading-6">
            İnternet sitemizi ve mobil uygularımızı kullanma alışkanlıklarınıza
            ilişkin istatistiki bilgiler üretmek,bu bilgileri raporlama ve
            analiz etme yöntemleriyle inceleyerek size özel araç kiralama
            teklifleri sunmak,
          </li>
          <li className="leading-6">
            Ürünlerimizi ve hizmetlerimizi sizlere tanıtmak,ilgilenebileceğiniz
            ve size fayda sağlayabileceğini düşündüğümüz promosyonlar ve
            kampanyalar oluşturup iletişim sağlayabilmek,
          </li>
          <li className="leading-6">
            acenta360 çalışanları, acenta360'a hizmet veren kuruluşlar veya
            bunların çalışanlarının veya acenta360'ın müşterilerinin can ve mal
            güvenliğinin korunması veya bu maddede belirtilenlere ilişkin
            kurallara uyum sağlanması dahil olmak üzere yasal yükümlülüklerin
            veya yetkili idari kuruluşların taleplerinin yerine getirilmesi,
            amacıyla kullanabilir.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Privacy;
