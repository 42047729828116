import { map } from 'lodash'

import { Container, Icon } from '../components'

import { ITEMS } from '../contants'

const Column = ({ children }) => (
  <div className="flex flex-col justify-center items-center md:flex-row md:justify-start md:items-start gap-4">
    {children}
  </div>
)

const Item = ({ item }) => (
  <div className="flex flex-col gap-4 items-center justify-center self-center py-4">
    <Icon name={item.icon} width={48} height={48} className="text-green" />
    <p className="text-sm text-gray-700">{item.title}</p>
  </div>
)

const Footer = () => {
  return (
    <div className="w-full bg-white text-gray-700 p-6">
      <Container className="flex flex-col md:flex-row justify-between items-center md:items-start gap-8 mb-16 md:mb-0">
        <Column>
          <img src="/logo.png" alt="Sigortam" className="mb-6 w-72" />
        </Column>
        <Column>
          <ul className="flex flex-col gap-2 items-center">
            <li>Trafik Sigortası</li>
            <li>Kasko</li>
            <li>Tamamlayıcı Sağlık</li>
            <li>Özel Sağlık</li>
            <li>DASK</li>
            <li>Konut Sigortası</li>
          </ul>
        </Column>
        <Column>
          {map(ITEMS, item => (
            <Item item={item} key={item.title} />
          ))}
        </Column>
      </Container>
    </div>
  )
}

export default Footer
