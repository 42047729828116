import { map } from 'lodash'
import { Icon } from './components'

const iconNames = [
  'activity',
  'airplay',
  'alert-circle',
  'alert-octagon',
  'alert-triangle',
  'align-center',
  'align-justify',
  'align-left',
  'align-right',
  'anchor',
  'aperture',
  'archive',
  'arrow-down-circle',
  'arrow-down-left',
  'arrow-down-right',
  'arrow-down',
  'arrow-left-circle',
  'arrow-left',
  'arrow-right-circle',
  'arrow-right',
  'arrow-up-circle',
  'arrow-up-left',
  'arrow-up-right',
  'arrow-up',
  'at-sign',
  'award',
  'bar-chart-2',
  'bar-chart',
  'battery-charging',
  'battery',
  'bell-off',
  'bell',
  'bluetooth',
  'bold',
  'book-open',
  'book',
  'bookmark',
  'box',
  'briefcase',
  'calendar',
  'camera-off',
  'camera',
  'cast',
  'check-circle',
  'check-square',
  'check',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'chevrons-down',
  'chevrons-left',
  'chevrons-right',
  'chevrons-up',
  'chrome',
  'circle',
  'clipboard',
  'clock',
  'cloud-drizzle',
  'cloud-lightning',
  'cloud-off',
  'cloud-rain',
  'cloud-snow',
  'cloud',
  'code',
  'codepen',
  'codesandbox',
  'coffee',
  'columns',
  'command',
  'compass',
  'copy',
  'corner-down-left',
  'corner-down-right',
  'corner-left-down',
  'corner-left-up',
  'corner-right-down',
  'corner-right-up',
  'corner-up-left',
  'corner-up-right',
]

const createIcons = () => {
  return map(iconNames, iconName => {
    return {
      text: () => (
        <div className="flex flex-row gap-4">
          <Icon name={iconName} /> <span>{iconName}</span>
        </div>
      ),
      value: iconName,
      key: iconName,
    }
  })
}

const getPhoneNumber = (number, type) => {
  if (!number) {
    return '0'
  }
  if (number.startsWith('0')) {
    return `9${number}`
  }
  return `90${number}`
}

const getWhatsappUrl = number => {
  const whatsappMessage =
    process.env.REACT_APP_WHATSAPP_MESSAGE ||
    'Merhabalar%20ara%C3%A7%20kiralamak%20istiyorum,bilgi%20alabilir%20miyim%20?'
  return `https://api.whatsapp.com/send?phone=${number}&text=${whatsappMessage}`
}

export { createIcons, getPhoneNumber, getWhatsappUrl }
