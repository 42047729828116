import { useState } from "react";
import { map } from "lodash";
import { Link, NavLink } from "react-router-dom";

import { Button, Icon } from "../components";

import { ROUTES } from "../routes";

const ITEMS = [
  {
    key: "arac-ekle",
    to: ROUTES.ADD_CAR,
    text: "Araç Ekle",
  },
  {
    key: "ozellik-ekle",
    to: ROUTES.ADD_FEATURE,
    text: "Özellik Ekle",
  },
  {
    key: "sart-ekle",
    to: ROUTES.ADD_CONDITION,
    text: "Şart Ekle",
  },
  {
    key: 'numara-ekle',
    to: ROUTES.ADD_NUMBER,
    text: 'Numara Ekle'
  },
  {
    key: "basvurular",
    to: ROUTES.APPLICATIONS,
    text: "Başvurular"
  }
];

const Item = ({ text, to }) => (
  <li className="hvr-grow hover:cursor-pointer">
    <Link to={to}>{text}</Link>
  </li>
);

const Menu = () => {
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

  return (
    <>
      <ul className="hidden md:flex flex-row justify-between items-center gap-8">
        {map(ITEMS, (item) => (
          <Item key={item.key} text={item.text} to={item.to} />
        ))}
      </ul>
      <Button type="button" as="button" onClick={() => setShowHamburgerMenu(prev => !prev)}>
        <Icon name="menu" />
      </Button>
      <ul className={`absolute left-0 w-full z-10 bg-primary h-full flex-col gap-4 font-bold text-3xl px-4 py-12 text-center justify-evenly opacity-90 top-20 transition-all ease-in-out duration-300 ${showHamburgerMenu ? 'flex' : 'hidden'}`}>
        {map(ITEMS, (item) => (
          <Item key={item.key} text={item.text} to={item.to} />
        ))}
      </ul>
    </>
  )
};

const AdminNavbar = () => {
  return (
    <div className="h-20 w-full bg-primary">
      <div className="md:w-container mx-auto flex flex-row justify-between items-center p-2 h-full text-white shadow-md sm:w-full sm:overflow-hidden">
        <h1><NavLink to={ROUTES.ADMIN}>Rental</NavLink></h1>
        <Menu />
      </div>
    </div>
  );
};

export default AdminNavbar;
