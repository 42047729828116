import { isNil } from 'lodash'

/* eslint-disable jsx-a11y/anchor-is-valid */
const Page = ({ pageNumber, isCurrent, onClick }) => {
  const classes = isCurrent
    ? 'relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20'
    : 'relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
  return (
    <a
      role="button"
      onClick={onClick}
      href="#"
      aria-current="page"
      className={classes}
    >
      {pageNumber}
    </a>
  )
}

const Prev = ({ disabled, onClick }) => {
  return (
    <a
      role="button"
      onClick={disabled ? () => {} : onClick}
      href="#"
      className={`${
        disabled && 'hover:cursor-not-allowed'
      } relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium ${
        disabled ? 'text-gray-400' : 'text-gray-700'
      } hover:bg-gray-50`}
    >
      Önceki
    </a>
  )
}

const Next = ({ disabled, onClick }) => {
  return (
    <a
      role="button"
      onClick={disabled ? () => {} : onClick}
      href="#"
      className={`${
        disabled && 'hover:cursor-not-allowed'
      } relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium ${
        disabled ? 'text-gray-400' : 'text-gray-700'
      } hover:bg-gray-50`}
    >
      Sonraki
    </a>
  )
}

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  totalElements,
  pageSize,
}) => {
  const pageRange = 2
  const startPage = Math.max(currentPage - pageRange, 2)
  const endPage = Math.min(currentPage + pageRange, totalPages)
  const from = pageSize * (currentPage - 1)
  const to =
    pageSize * currentPage > totalElements
      ? totalElements
      : pageSize * currentPage
  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <Prev
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
        />
      </div>
      <div className="flex flex-1 justify-between sm:hidden">
        <Next
          disabled={currentPage === totalPages}
          onClick={() => onPageChange(currentPage + 1)}
        />
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        {!isNil(totalElements) && (
          <div>
            <p className="text-sm text-gray-700">
              <span className="font-semibold">{totalElements}</span> sonuçtan{' '}
              <span className="font-semibold">{from}</span> ile{' '}
              <span className="font-semibold">{to}</span> arası sonuçlar
              gösteriliyor.
            </p>
          </div>
        )}
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <Prev
              disabled={currentPage === 1}
              onClick={() => onPageChange(currentPage - 1)}
            />
            <Page
              key={`page_1`}
              pageNumber={1}
              onClick={() => onPageChange(1)}
              isCurrent={currentPage === 1}
            />
            {startPage > 2 && (
              <span className="text-gray-500 px-3 py-1 rounded">...</span>
            )}

            {Array.from(
              { length: endPage - startPage + 1 },
              (_, i) => startPage + i,
            ).map(page => (
              <Page
                key={`page_${page}`}
                pageNumber={page}
                onClick={() => onPageChange(page)}
                isCurrent={currentPage === page}
              />
            ))}
            {endPage < totalPages - 1 && (
              <span className="text-gray-500 px-3 py-1 rounded">...</span>
            )}
            {endPage < totalPages && (
              <Page
                pageNumber={totalPages}
                className="text-gray-500 px-3 py-1 rounded"
                onClick={() => onPageChange(totalPages)}
              />
            )}
            {currentPage < totalPages && (
              <Next
                disabled={currentPage === totalPages}
                onClick={() => onPageChange(currentPage + 1)}
              />
            )}
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Pagination
