const ROUTES = Object.freeze({
    HOME: '/',
    ADMIN: '/yonetim',
    LOGIN: '/yonetim/giris',
    ADD_CAR: '/yonetim/arac-ekle',
    ADD_FEATURE: '/yonetim/ozellik-ekle',
    ADD_CONDITION: '/yonetim/sart-ekle',
    ADD_NUMBER: '/yonetim/numara-ekle',
    PRIVACY: '/gizlilik',
    APPLICATIONS: '/yonetim/basvurular',
});

const PUBLIC_PATH = process.env.REACT_APP_BACKEND_BASE_PATH + '/static';

export { ROUTES, PUBLIC_PATH };