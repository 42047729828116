import Compressor from "compressorjs";
import { useRef } from "react";

const Upload = ({ onChange, content, image, name }) => {
  const hiddenFileInput = useRef(null);

  const handleClick = (e) => {
    e.preventDefault();
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (!file) {
      alert("Resim bulunamadı.");
      return;
    }

    new Compressor(file, {
      quality: 0.8,
      success(compressedFile) {
        onChange(compressedFile);
      },
      error(err) {
        console.log("Resim optimizasyonu yaparken bir hata oluştu. Hata: ", err);
        alert("Resim optimizasyonu yaparken bir hata oluştu.");
      }
    })
  };

  return (
    <div className="my-4 flex flex-row items-center">
      <button
        className="w-48 h-48 border bg-slate-200 shadow-md"
        onClick={handleClick}
      >
        {content}
      </button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
        name={name}
      />
      {image && <img src={image} className="w-72 h-auto" alt="uploaded" />}
    </div>
  );
};

export default Upload;
