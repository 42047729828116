import { Helmet } from 'react-helmet'
import { useState } from 'react'

import { Navbar, Footer } from '../sections'
import { CallButton, Container, Section } from '../components'
import { get } from '../api'
import { getPhoneNumber, getWhatsappUrl } from '../util'

const PublicRoute = ({ component: Component, title, ...rest }) => {
  const [currentNumber, setCurrentNumber] = useState('#')
  const parsedTitle = title
    ? `${process.env.REACT_APP_NAME} - ${title}`
    : process.env.REACT_APP_NAME

  const handleNumberClick = async (cb, type) => {
    if (currentNumber === '#') {
      try {
        const res = await get('/random-number')
        const parsedNumber = getPhoneNumber(res.number.value)
        const finalNumber =
          type === 'whatsapp'
            ? getWhatsappUrl(parsedNumber)
            : `tel:+${parsedNumber}`
        setCurrentNumber(finalNumber)
        cb()
      } catch (err) {
        console.error('Something went wrong.', err)
      }
    } else {
      if (type === 'whatsapp') {
        const actualNumber = currentNumber.startsWith('tel:')
          ? currentNumber.substring(5)
          : currentNumber
        setCurrentNumber(getWhatsappUrl(actualNumber))
      }
      cb()
    }
  }

  return (
    <>
      <Helmet>
        <title>{parsedTitle}</title>
      </Helmet>
      <Container fluid>
        <Section name="navbar" stretched>
          <Container fluid>
            <Navbar />
          </Container>
        </Section>
        <Component {...rest} />
        <Section name="footer" stretched>
          <Container fluid>
            <Footer />
          </Container>
        </Section>
        <CallButton
          position="left"
          text="Hemen Ara"
          icon="phone"
          currentNumber={currentNumber}
          onClick={cb => handleNumberClick(cb, 'general')}
        />
        <CallButton
          position="right"
          text="Whatsapp"
          icon="smartphone"
          color="#25D366"
          onClick={cb => handleNumberClick(cb, 'whatsapp')}
          currentNumber={currentNumber}
        />
      </Container>
    </>
  )
}

export default PublicRoute
