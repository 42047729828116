import React from 'react'

const Button = React.forwardRef(
  (
    { children, content, as, onClick, fluid, type, className, ...rest },
    ref,
  ) => (
    <button
      as={as}
      ref={ref}
      type={type}
      onClick={onClick}
      className={`gradient-bg text-white rounded-full h-10 shadow-sm hover:shadow-md transition-300 ease-in-out font-bold px-4 ${
        fluid ? 'w-full' : 'w-auto'
      } ${className}`}
      {...rest}
    >
      {children || content}
    </button>
  ),
)

export default Button
