import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import './index.css'

import { isAuthenticated } from './auth'
import { ROUTES } from './routes'

import Page404 from './pages/Page404'
import Admin from './pages/Admin'
import AddCar from './pages/AddCar'
import AddCondition from './pages/AddCondition'
import AddFeature from './pages/AddFeature'
import Login from './pages/Login'
import ProtectedRoute from './components/ProtectedRoute'
import PublicRoute from './components/PublicRoute'
import AddNumber from './pages/AddNumber'
import Privacy from './pages/Privacy'
import Applications from './pages/Applications'

const router = createBrowserRouter([
  {
    path: ROUTES.HOME,
    element: <Page404 />,
  },
  {
    path: ROUTES.LOGIN,
    element: isAuthenticated() ? (
      <ProtectedRoute component={Admin} />
    ) : (
      <PublicRoute component={Login} />
    ),
  },
  {
    path: ROUTES.ADMIN,
    element: <ProtectedRoute component={Admin} />,
  },
  {
    path: ROUTES.ADD_CAR,
    element: <ProtectedRoute component={AddCar} />,
  },
  {
    path: ROUTES.ADD_FEATURE,
    element: <ProtectedRoute component={AddFeature} />,
  },
  {
    path: ROUTES.ADD_CONDITION,
    element: <ProtectedRoute component={AddCondition} />,
  },
  {
    path: ROUTES.ADD_NUMBER,
    element: <ProtectedRoute component={AddNumber} />,
  },
  {
    path: ROUTES.APPLICATIONS,
    element: <ProtectedRoute component={Applications} />,
  },
  {
    path: ROUTES.PRIVACY,
    element: <PublicRoute component={Privacy} title="Gizlilik Şartları" />,
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
)
