import { map } from 'lodash'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { useState } from 'react'

import { ROUTES } from '../routes'
import { CallButton } from '../components'
import { get } from '../api'
import { getPhoneNumber, getWhatsappUrl } from '../util'

const ITEMS = [
  {
    key: 'urunlerimiz',
    href: '#urunlerimiz',
    text: 'Ürünlerimiz',
  },
  {
    key: 'nasil',
    href: '#nasil',
    text: 'Nasıl yapılır?',
  },
  {
    key: 'musterilerimiz',
    href: '#musterilerimiz',
    text: 'Müşterilerimiz',
  },
  {
    key: 'hakkimizda',
    href: '#hakkimizda',
    text: 'Hakkımızda',
  },
]

const Item = ({ text, href }) => (
  <li className="hover:cursor-pointer hover:gradient transition-all duration-300">
    <HashLink smooth to={href}>
      {text}
    </HashLink>
  </li>
)

const Navbar = () => {
  const [currentNumber, setCurrentNumber] = useState('#')

  const handleNumberClick = async (cb, type) => {
    if (currentNumber === '#') {
      try {
        const res = await get('/random-number')
        const parsedNumber = getPhoneNumber(res.number.value)
        const finalNumber =
          type === 'whatsapp'
            ? getWhatsappUrl(parsedNumber)
            : `tel:+${parsedNumber}`
        setCurrentNumber(finalNumber)
        cb()
      } catch (err) {
        console.error('Something went wrong.', err)
      }
    } else {
      if (type === 'whatsapp') {
        const actualNumber = currentNumber.startsWith('tel:')
          ? currentNumber.substring(5)
          : currentNumber
        setCurrentNumber(getWhatsappUrl(actualNumber))
      }
      cb()
    }
  }

  return (
    <div className="h-20 w-full bg-gray-50 px-4 fixed top-0 shadow-sm">
      <div className="max-w-container mx-auto flex flex-row justify-between items-center h-full text-primary">
        <h1 className="mx-2">
          <Link to={ROUTES.HOME}>
            <img
              src="/logo.png"
              alt={process.env.REACT_APP_NAME}
              className="w-56"
            />
          </Link>
        </h1>
        <ul className="hidden md:flex flex-row justify-between items-center gap-8 mx-2">
          {map(ITEMS, item => (
            <Item key={item.key} {...item} />
          ))}
        </ul>
        <CallButton
          text="Whatsapp"
          icon="smartphone"
          color="#25D366"
          onClick={cb => handleNumberClick(cb, 'whatsapp')}
          currentNumber={currentNumber}
          className="relative right-0 bottom-0 hover:cursor-pointer"
        />
      </div>
    </div>
  )
}

export default Navbar
