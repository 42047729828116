import { map } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { get, post } from '../api'
import { Container, Icon, Section } from '../components'
import { AdminNavbar } from '../sections'
import useSWR from 'swr'

const SectionContainer = ({ label, children }) => {
  return (
    <div className="flex flex-col gap-4 flex-1">
      {label && <h2 className="font-bold text-lg">{label}</h2>}
      {children}
    </div>
  )
}

const fetcher = async url => {
  const res = await get(url)
  return res.success ? res.data : {}
}

const Admin = () => {
  const navigate = useNavigate()
  const { data } = useSWR('/init', fetcher)

  const removeItem = async (type, itemId) => {
    const res = await post(`/remove/${type}`, { itemId })
    if (res && res.success) {
      alert('Silme işlemi başarılı. Yeniden yönlendiriliyorsunuz...')
      setTimeout(() => {
        navigate(0)
      }, 1500)
    } else {
      alert('Silinme sırasında bir hata oluştu.')
    }
  }

  const { cars, features, conditions, numbers } = data || {}

  return (
    <Container fluid>
      <AdminNavbar />
      <Container className="flex flex-row gap-4 flex-1 mt-12">
        <SectionContainer label="Arabalar">
          <Section
            name="cars"
            className="border shadow-md rounded-md flex-1 overflow-y-auto max-h-itemCard"
          >
            <div className="flex flex-col gap-4">
              {map(cars, car => (
                <div
                  key={car._id}
                  className="flex flex-row justify-between items-center"
                >
                  <p>{car.name}</p>
                  <Icon
                    name="trash"
                    stroke="#f56565"
                    role="button"
                    aria-hidden="true"
                    onClick={() => removeItem('car', car._id)}
                  />
                </div>
              ))}
            </div>
          </Section>
        </SectionContainer>
        <SectionContainer label="Özellikler">
          <Section
            name="features"
            className="border shadow-md rounded-md flex-1 overflow-y-auto max-h-itemCard"
          >
            <div className="flex flex-col gap-4">
              {map(features, feature => (
                <div
                  key={feature._id}
                  className="flex flex-row justify-between items-center"
                >
                  <p>
                    {feature.name} - {feature.value}
                  </p>
                  <Icon
                    name="trash"
                    stroke="#f56565"
                    role="button"
                    aria-hidden="true"
                    onClick={() => removeItem('feature', feature._id)}
                  />
                </div>
              ))}
            </div>
          </Section>
        </SectionContainer>
        <SectionContainer label="Şartlar">
          <Section
            name="conditions"
            className="border shadow-md rounded-md flex-1 overflow-y-auto max-h-itemCard"
          >
            <div className="flex flex-col gap-4">
              {map(conditions, condition => (
                <div
                  key={condition._id}
                  className="flex flex-row justify-between items-center"
                >
                  <p>
                    {condition.name} - {condition.value}
                  </p>
                  <Icon
                    name="trash"
                    stroke="#f56565"
                    role="button"
                    aria-hidden="true"
                    onClick={() => removeItem('condition', condition._id)}
                  />
                </div>
              ))}
            </div>
          </Section>
        </SectionContainer>
        <SectionContainer label="Numaralar">
          <Section
            name="numbers"
            className="border shadow-md rounded-md flex-1 overflow-y-auto max-h-itemCard"
          >
            <div className="flex flex-col gap-4">
              {map(numbers, number => (
                <div
                  key={number._id}
                  className="flex flex-row justify-between items-center"
                >
                  <p>
                    {number.name} - {number.value}
                  </p>
                  <Icon
                    name="trash"
                    stroke="#f56565"
                    role="button"
                    aria-hidden="true"
                    onClick={() => removeItem('number', number._id)}
                  />
                </div>
              ))}
            </div>
          </Section>
        </SectionContainer>
      </Container>
    </Container>
  )
}

export default Admin
