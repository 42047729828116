import React from 'react'
import PropTypes from 'prop-types'

const Modal = ({ children, show, className, onClose, fluid }) => {
  return (
    <div
      className={`${
        show ? 'flex flex-col gap-4' : 'hidden'
      } shadow-lg rounded-md p-8 bg-white border border-card fixed top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 ${
        fluid ? 'w-screen' : 'w-full'
      } md:w-1/3 ${className}`}
    >
      <button className="absolute right-4 top-4" onClick={onClose}>
        X
      </button>
      {children}
    </div>
  )
}

Modal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  fluid: PropTypes.bool,
}

Modal.defaultProps = {
  show: false,
  onClose: false,
  fluid: false,
}

export default Modal
