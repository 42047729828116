import Cookies from 'universal-cookie'
const cookies = new Cookies()

const headers = {
  Authorization: cookies.get(process.env.REACT_APP_COOKIE_NAME),
  'Content-Type': 'application/json',
}
const basePath = process.env.REACT_APP_API_PATH || 'http://localhost:5000/api'

const get = async (url, customHeaders = {}) => {
  const request = new Request(`${basePath}${url}`, {
    method: 'GET',
    headers: new Headers({ ...headers, ...customHeaders }),
  })

  try {
    const res = await fetch(request)
    const data = await res.json()
    return data
  } catch (err) {
    console.error('Something went wrong with request. URL:', url, err)
    return null
  }
}

const post = async (url, data, customHeaders = {}) => {
  const isFormData = data instanceof FormData
  const newHeaders = { ...headers, ...customHeaders }
  const body = isFormData ? data : JSON.stringify(data)
  if (isFormData) {
    delete newHeaders['Content-Type']
  }
  const request = new Request(`${basePath}${url}`, {
    method: 'POST',
    headers: new Headers(newHeaders),
    body,
  })
  try {
    const res = await fetch(request)
    const data = await res.json()
    return data
  } catch (err) {
    console.error('Something went wrong with request. URL:', url, err)
    return null
  }
}

export { get, post }
