import { useState } from 'react'
import useSWR from 'swr'
import { useNavigate } from 'react-router-dom'
import { filter, includes, map } from 'lodash'

import {
  Button,
  Checkbox,
  Container,
  FormRow,
  Input,
  Upload,
} from '../components'

import { AdminNavbar } from '../sections'
import { get, post } from '../api'
import { ROUTES } from '../routes'

const fetcher = async url => {
  const res = await get(url)
  return res
}

const AddCar = () => {
  const navigate = useNavigate()
  const { data: features } = useSWR('/features', fetcher)
  const { data: conditions } = useSWR('/conditions', fetcher)
  const [data, setData] = useState({
    name: '',
    type: '',
    price: '',
    image: null,
    features: [],
    conditions: [],
  })

  const handleChange = e => {
    const { target } = e
    const { name, value } = target
    setData(prev => ({ ...prev, [name]: value }))
  }

  const handleFeatureChange = (e, feature) => {
    const { target } = e
    const { checked } = target
    setData(prev => ({
      ...prev,
      features: checked
        ? [...data.features, feature]
        : filter(prev.features, prevFeature => feature !== prevFeature),
    }))
  }

  const handleConditionChange = (e, condition) => {
    const { target } = e
    const { checked } = target
    setData(prev => ({
      ...prev,
      conditions: checked
        ? [...data.conditions, condition]
        : filter(prev.conditions, prevCondition => condition !== prevCondition),
    }))
  }

  const isChecked = (items, item) => includes(items, item)

  const handleImageChange = image => {
    setData(prev => ({ ...prev, image }))
  }

  const handleSave = async e => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('image', data.image)
    const res = await post('/uploadImage', formData, {
      'Content-Type': 'multipart/form-data',
    })
    if (res && res.success) {
      const newCarRes = await post('/car', { ...data, image: res.fileName })
      if (newCarRes && newCarRes.success) {
        alert('Yeni araç eklendi. Yönlendiriliyorsunuz...')
        setTimeout(() => {
          navigate(ROUTES.ADMIN)
        }, 1500)
      } else {
        alert('Araç ekleme başarısız oldu.')
      }
    } else {
      alert('Resim yükleme başarısız oldu.')
    }
  }

  return (
    <Container fluid>
      <AdminNavbar />
      <Container style={{ marginTop: '4rem' }}>
        <form method="POST" action="/">
          <FormRow>
            <Input
              label="Araç İsmi"
              placeholder="Araç ismini yazın..."
              value={data.name}
              name="name"
              onChange={handleChange}
              fluid
            />
            <Input
              label="Araç Türü"
              placeholder="Araç türünü yazın..."
              value={data.type}
              name="type"
              onChange={handleChange}
              fluid
            />
            <Input
              label="Araç Fiyatı"
              placeholder="Araç fiyatını yazın..."
              value={data.price}
              name="price"
              onChange={handleChange}
              fluid
            />
          </FormRow>
          <div className="h-8" />
          <FormRow>
            <div className="flex flex-1 flex-col">
              <p className="font-bold text-xl mb-2">Araç Özellikleri</p>
              {map(features, feature => (
                <Checkbox
                  key={feature._id}
                  id={feature._id}
                  label={feature.value}
                  checked={isChecked(data.features, feature._id)}
                  onChange={e => handleFeatureChange(e, feature._id)}
                />
              ))}
            </div>
            <div className="flex flex-1 flex-col">
              <p className="font-bold text-xl mb-2">Kullanım Şartları</p>
              {map(conditions, condition => (
                <Checkbox
                  key={condition._id}
                  id={condition._id}
                  label={condition.value}
                  checked={isChecked(data.conditions, condition._id)}
                  onChange={e => handleConditionChange(e, condition._id)}
                />
              ))}
            </div>
          </FormRow>
          <div className="h-8" />
          <FormRow>
            <Upload
              onChange={handleImageChange}
              content="Resim Yükle"
              image={data.image && URL.createObjectURL(data.image)}
              name="image"
            />
          </FormRow>
          <div className="h-8" />
          <FormRow>
            <Button type="submit" onClick={handleSave} content="Kaydet" fluid />
          </FormRow>
        </form>
      </Container>
    </Container>
  )
}

export default AddCar
